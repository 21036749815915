import { Grid, Typography, Container, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import axios from '../../../../InstanceAxios/InstanceAxios'
import NavBarRetro from '../../SharedRetro/NavBarRetro/NavBarRetro';
import AddToBagButtonBigRetro from '../../SharedRetro/Buttons/AddToBagButtonBigRetro/AddToBagButtonBigRetro';
import GoToBagRetro from '../../SharedRetro/Buttons/GoToBagRetro/GoToBagRetro';
import BuyNowButtonBigRetro from '../../SharedRetro/Buttons/BuyNowButtonBigRetro/BuyNowButtonBigRetro';
import YouMayAlsoLikeRetro from '../YouMayAlsoLikeRetro/YouMayAlsoLikeRetro';
import FooterRetro from '../../SharedRetro/FooterRetro/FooterRetro';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";
import { cartProduct, updateCart } from '../../../../utilities/cartProduct';


const useStyles = makeStyles({
    bigImg: {

        objectFit: 'cover',
        width: 'auto',
        height: 'auto',
        maxHeight: '100%',
        maxWidth: '100%',
        aspectRatio: '1/1',


    },
    bigImgRes: {

        objectFit: 'cover',
        width: '100%',
    },

    showBorder: {
        border: '2px solid #000000',
    },
});


const Price = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: '22px',
    // lineHeight: '24px',
    marginRight: '8px',
    marginBottom: '0px',
    fontFamily: 'Quicksand',

    [theme.breakpoints.down('md')]: {
        fontSize: '5.353vw',
        fontWeight: 400
    },

}));
const Bdt = styled('span')(({ theme }) => ({
    fontWeight: 500,
    fontSize: '20px',
    // lineHeight: '24px',
    marginRight: '8px',
    marginBottom: '0px',

    [theme.breakpoints.down('md')]: {
        fontSize: '5.353vw',
        fontWeight: 400
    },

}));



const ProductDetailsRetro = () => {
    const [details, setDetails] = useState({});

    const { pid, shop_link } = useParams();
    const dispatch = useDispatch();
    const params = useParams();
    const [imageIndex, setImageIndex] = useState(0);
    const [select, setSelect] = useState([]);
    const [price, setPrice] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(0);
    const [variantId, setVariantId] = useState(0);

    const [stock, setStock] = useState(0);
    const classes = useStyles();
    let shop = useSelector((state) => state?.product?.shop)
    // console.log(shop);
    const name = process.env.React_App_NAME

    const hostName = window.location.hostname;

    const cart = useSelector((state) => state?.cart)

    const cartItems = cart?.cartItems;

    const carts = cartItems?.filter(cartItem => cartItem?.shop_id === shop?.shop_id)
    // console.log(cart);
    let value = 0
    let product = {}


    const url = `/products/getSingleProductV3/${pid}?source=webStore`
    useEffect(() => {

        const fetchData = async () => {
            const response = await axios.get(url)
            setDetails(response.data)
        }
        fetchData();



    }, [pid])
    // console.log(details);
    // console.log(stock);
    details.variant_products && value++


    product = cartProduct(details);


    useEffect(() => {

        details?.stock && (setStock(details?.stock[0]?.number_of_stocks), setPrice(details?.unit_selling_price))

        if (details.variant_names && details.variant_names[0]) {
            let detail = { ...details }
            detail = detail?.variant_names.map((v) => {
                const result = v.variant_values.map((r, index) => {
                    // console.log(r);
                    if (index === 0) {
                        return ({ ...r, isSelected: true })

                    }
                    else {
                        return ({ ...r, isSelected: false })
                    }

                })
                // console.log(result);
                return ({ ...v, variant_values: [...result] })

            })

            // console.log({ ...details, variant_names: [...detail] });
            setDetails({ ...details, variant_names: [...detail] })
        }


        let tempSelect = []
        for (let i = 0; i < details?.variant_names?.length; i++) {
            tempSelect.push({
                'title': details?.variant_names[i]?.title,
                'value': details?.variant_products[0]?.variant_value_variant_product[i]?.v_value_id,
                'vTitle': details?.variant_names[i]?.variant_values[0].title

            })
        }
        // console.log(tempSelect);
        setSelect(tempSelect);
        let temp = {}
        let discount
        details.variant_products && (details?.variant_products[0] && (setPrice(details?.variant_products[0]?.selling_price),
            setImageIndex(details?.variant_products[0]?.image_index),
            setStock(details?.variant_products[0]?.in_stock),
            setVariantId(details?.variant_products[0]?.id),
            temp.amount_off = details?.variant_products[0]?.amount_off,
            temp.percentage_off = details?.variant_products[0]?.percentage_off,
            temp.unit_discounted_price = null,
            details?.variant_products[0]?.percentage_off && (
                discount = (details?.variant_products[0]?.percentage_off / 100) * price,
                temp.unit_discounted_price = price - discount
            ),

            details?.variant_products[0]?.amount_off && (
                temp.unit_discounted_price = price - details?.variant_products[0]?.amount_off
            ),

            setDiscountPrice(temp)
            // console.log('temp', temp)
        )
        )

        details.variant_products && (!details?.variant_products[0]?.image_index && (setImageIndex(0)))

    }, [value])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pid])


    const handleClick = (id) => {
        // console.log(id);
        setImageIndex(id)

    }

    //price section for discounted product
    let content;

    if (details.percentage_off) {
        content = <Box sx={{ display: 'flex' }}>
            <Price sx={{ color: '#000000' }}>{shop?.currencies?.title} {details.unit_discounted_price}</Price>
            <del style={{ color: ' #95989A' }}><Price>{shop?.currencies?.title} {details.unit_selling_price}</Price></del>
            <Price style={{ color: ' #000000' }}>({details.percentage_off}% off)</Price>
        </Box>
    }

    if (discountPrice?.percentage_off) {
        const discount = (discountPrice?.percentage_off / 100) * price
        content = <Box sx={{ display: 'flex' }}>
            <Price sx={{ color: '#000000' }}>{shop?.currencies?.title} {price - discount}</Price>
            <del style={{ color: ' #95989A' }}><Price>{shop?.currencies?.title} {price}</Price></del>
            <Price style={{ color: ' #D03330' }}>({discountPrice?.percentage_off}% off)</Price>
        </Box>
    }

    else if (details.amount_off) {
        content = <Box sx={{ display: 'flex' }}>
            <Price sx={{ color: '#000000' }}>{shop?.currencies?.title}  {details.unit_discounted_price}</Price>
            <del style={{ color: ' #95989A' }}><Price>{shop?.currencies?.title} {details.unit_selling_price}</Price></del>
            <Price style={{ color: ' #000000' }}>({shop?.currencies?.title} {details.amount_off} off)</Price>
        </Box>
    }

    else {
        content = <Price sx={{ color: '#000000' }}>{shop?.currencies?.title} {details.unit_selling_price}</Price>
    }


    const description = details?.product_description?.replace(/\n/g, "<br/>")

    const handleClickVariant = (variant, value, val, id) => {
        // console.log(details);

        // console.log(val);
        let detail = { ...details }
        detail = detail.variant_names.map((v) => {
            const result = v.variant_values.map((r) => {
                // console.log(r);
                if (r.id === val.id && v.id === id) {
                    return ({ ...r, isSelected: true })

                }
                else if (v.id === id) {
                    return ({ ...r, isSelected: false })
                }
                else {
                    return ({ ...r })
                }
            })
            // console.log(result);
            return ({ ...v, variant_values: [...result] })

        })

        // console.log({ ...details, variant_names: [...detail] });
        setDetails({ ...details, variant_names: [...detail] })
        // console.log(details);
        let tempResult
        let temp = [...select]
        // console.log(temp);
        let s = -1;
        temp.length === 0 ? temp.push({ 'title': variant, 'value': value, 'vTitle': val.title }) : (temp.map((r, index) => {
            if (r.title === variant) {
                s = index
                // console.log({ ...r, value: value, vTitle: val.title });
                tempResult = { ...r, value: value, vTitle: val.title }
                // r.value = value,
                //     r.vTitle = val.title,

            }

            // temp[index]=
        }

        ),
            temp[s] = tempResult,
            // console.log('tempResult', tempResult),
            s < 0 && temp.push({ 'title': variant, 'value': value, 'vTitle': val.title })
        )
        setSelect(temp)
        console.log('select', select);


        details.variant_products.map(v => {

            let p = 0

            for (let i = 0; i < v.variant_value_variant_product.length; i++) {
                for (let j = 0; j < temp.length; j++) {
                    if (v.variant_value_variant_product[i].v_value_id === temp[j].value) {
                        console.log(v.variant_value_variant_product[i].v_value_id);
                        console.log(temp[j].value);
                        p++

                    }
                }
            }

            let tempV = {}
            let discount
            p === temp.length && (
                console.log(p),
                console.log('v', v),
                setPrice(v.selling_price),
                setImageIndex(v.image_index),
                setStock(v.in_stock),
                setVariantId(v.id),
                tempV.amount_off = v.amount_off,
                tempV.percentage_off = v.percentage_off,
                tempV.unit_discounted_price = null,
                v?.percentage_off && (
                    discount = (v?.percentage_off / 100) * price,
                    tempV.unit_discounted_price = price - discount
                ),

                v?.amount_off && (
                    tempV.unit_discounted_price = price - v?.amount_off
                ),

                setDiscountPrice(tempV)
                // console.log('temp', temp)
            )




        })

    }
    // console.log(select);
    product = updateCart(price, stock, variantId, select, discountPrice)
    console.log(product);


    if (shop?.facebook_pixel_id) {
        ReactPixel.pageView();
    }
    if (shop?.google_analytics_measurement_id) {
        ReactGA.send("pageview");
    }


    return (
        <>
            <NavBarRetro />
            <Box sx={{ backgroundColor: '#FFFFFF', marginTop: '32px' }}>


                <Container sx={{ marginTop: '20px' }}>
                    <Grid container spacing={3} sx={{ marginBottom: '32px' }}>
                        <Grid item md={6} sx={{ marginBottom: '0px' }}>



                            {/*  , position: 'Fixed', height: '100%', width: '38.8%'*/}
                            <Grid container spacing={3} sx={{ display: { xs: 'none', md: 'flex' }, marginTop: '30px' }}>
                                <Grid item md={2}>
                                    {
                                        details?.product_images?.map(image => <img
                                            key={image.image_id}
                                            className={image.image_url == details?.product_images[imageIndex]?.image_url ? classes.showBorder : ''}
                                            onClick={() => handleClick(details?.product_images?.findIndex(index => index?.image_id == image.image_id))}
                                            style={{ width: 'auto', height: 'auto', maxHeight: '100%', maxWidth: '100%', aspectRatio: '1/1', objectFit: 'cover' }}
                                            src={image.image_url}
                                        />)
                                    }

                                </Grid>
                                <Grid item md={10}>
                                    {
                                        details?.product_images ? (
                                            <>
                                                <img className={classes.bigImg} src={details?.product_images[imageIndex]?.image_url} />
                                                {/* <Helmet>
                                    <meta charSet="utf-8" />
                                    <title>{details?.product_name}</title>
                                    <meta name="title" content={details?.product_name} />
                                    <meta name="description" content="product details" />
                                    <link rel="canonical" href="https://bonik.store/:shop_link/products/:pid" />
                                    <link rel="apple-touch-icon" href={details?.product_images[0]?.image_url} />
                                    <meta property="og:image" content="https://bonik.blob.core.windows.net/product-images/1656678148652-35d4f234-c823-4e6e-b27a-b7ca40f16dca.jpg" />
                                </Helmet> */}
                                            </>

                                        ) : (<CircularProgress
                                            sx={{ color: '#000000', marginLeft: '50%', marginTop: '30px' }}
                                            size={40}
                                            thickness={4} />)


                                    }
                                </Grid>


                            </Grid>



                            <Grid container spacing={{ md: 2 }} sx={{ display: { xs: 'block', md: 'none' }, marginTop: '20px' }}>

                                <Grid item xs={12}>
                                    {
                                        details.product_images ? (
                                            <div>
                                                <img className={classes.bigImg} src={details?.product_images[imageIndex]?.image_url} />

                                            </div>
                                        ) : (<CircularProgress
                                            sx={{ color: '#000000', marginLeft: '50%', marginTop: '20px' }}
                                            size={40}
                                            thickness={4} />)


                                    }
                                </Grid>
                                <Grid item xs={12} sx={{ textAlign: 'left', marginTop: '12px' }}>

                                    {
                                        details?.product_images?.map(image => <img key={image.image_id} className={image.image_url == details?.product_images[imageIndex]?.image_url ? classes.showBorder : ''} onClick={() => handleClick(details?.product_images?.findIndex(index => index?.image_id == image.image_id))} style={{ width: '100px', height: '100px', objectFit: 'cover' }} src={image?.image_url} />)
                                    }
                                </Grid>


                            </Grid>

                        </Grid>

                        <Grid item md={6} xs={12} sx={{ textAlign: 'left', width: '100%', marginTop: '50px', display: { md: 'block', xs: 'none' } }}>

                            {
                                stock ? (
                                    <Typography sx={{ fontWeight: 500, fontSize: '12px', lineHeight: '16px', color: '#00DD64', textAlign: 'left', marginBottom: '12px', fontFamily: 'Quicksand' }}>{stock} piece(s) in stock</Typography>
                                ) : <Typography sx={{ fontWeight: 500, fontSize: '12px', lineHeight: '16px', color: '#D03330', textAlign: 'left', marginBottom: '12px', fontFamily: 'Quicksand' }}>Out of stock</Typography>
                            }

                            <Typography sx={{ fontWeight: 500, fontSize: '22px', lineHeight: '27px', color: '#000000', textAlign: 'left', marginBottom: '12px', fontFamily: 'Quicksand' }}>{details?.product_name}</Typography>

                            {
                                details.unit_discounted_price ? <Typography sx={{ fontWeight: 400, fontSize: '28px', lineHeight: '36px', color: '#000000', textAlign: 'left', marginBottom: '14px', fontFamily: 'Quicksand' }}>{content}</Typography>
                                    : <Typography sx={{ fontWeight: 400, fontSize: '28px', lineHeight: '36px', color: '#000000', textAlign: 'left', marginBottom: '14px', fontFamily: 'Quicksand' }}>{content}</Typography>
                            }
                            {/* <Typography sx={{ fontWeight: 400, fontSize: '28px', lineHeight: '36px', color: '#000000', textAlign: 'left', marginBottom: '14px', fontFamily: 'Quicksand' }}>{content}</Typography> */}

                            {/* variants */}
                            {
                                details?.variant_names && details?.variant_names.map(v => (
                                    <Box sx={{ display: 'flex', marginBottom: '24px' }}>
                                        <Typography sx={{ width: '80px', fontSize: '16px', color: '#000000', fontFamily: 'Quicksand' }}>{v.title}</Typography>
                                        {
                                            v.variant_values.map(val => <Box className='chip' sx={{
                                                backgroundColor: val.isSelected ? '#000000' : '#FFFFFF', color: val.isSelected ? '#FFFFFF' : '#000000', paddingX: '16px', paddingY: '4px', border: '1px solid #000000', marginRight: '14px'
                                            }} onClick={() => handleClickVariant(v.title, val.id, val, v.id)}>
                                                <Typography>{val.title}</Typography>
                                            </Box>)
                                        }
                                    </Box>

                                ))
                            }



                            <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px', color: '#000000', textAlign: 'left', marginBottom: '12px', fontFamily: 'Quicksand' }}>Product Details</Typography>
                            <div style={{ color: '#79747E' }} dangerouslySetInnerHTML={{ __html: description }}>

                            </div>
                            {/* <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '20px', color: '#79747E', textAlign: 'left', marginBottom: '12px' }}>{details?.product_description}</Typography> */}

                            <Box sx={{ marginTop: '48px' }}>

                            </Box>
                            <Grid container spacing={{ md: 3, xs: 2 }} >
                                <Grid item md={6} xs={6}>
                                    {
                                        // details?.stock[0]?.number_of_stocks === 0 ? '' : 

                                    }
                                    <AddToBagButtonBigRetro
                                        product={product}
                                        stock={stock}


                                    ></AddToBagButtonBigRetro>

                                </Grid>

                                <Grid item md={6} xs={6}>
                                    {
                                        carts[0] ? (<GoToBagRetro></GoToBagRetro>) : (<BuyNowButtonBigRetro></BuyNowButtonBigRetro>)
                                    }
                                </Grid>

                            </Grid>


                        </Grid>
                        <Grid item md={6} xs={12} sx={{ textAlign: 'left', width: '100%', display: { md: 'none' } }}>
                            {
                                stock ? (
                                    <Typography sx={{ fontWeight: 500, fontSize: '12px', lineHeight: '16px', color: '#00DD64', textAlign: 'left', marginBottom: '12px', fontFamily: 'Quicksand' }}>{stock} piece(s) in stock</Typography>
                                ) : (<Typography sx={{ fontWeight: 500, fontSize: '12px', lineHeight: '16px', color: '#D03330', textAlign: 'left', marginBottom: '12px', fontFamily: 'Quicksand' }}>Out of stock</Typography>)



                            }

                            <Typography sx={{ fontWeight: 500, fontSize: '22px', lineHeight: '27px', color: '#000000', textAlign: 'left', marginBottom: '12px', fontFamily: 'Quicksand' }}>{details?.product_name}</Typography>
                            {
                                details?.variant_products && (details?.variant_products[0] ? <Typography sx={{ fontWeight: 400, fontSize: '28px', lineHeight: '36px', color: '#000000', textAlign: 'left', marginBottom: '14px', fontFamily: 'Quicksand' }}> {content}</Typography> :
                                    <Typography sx={{ fontWeight: 400, fontSize: '28px', lineHeight: '36px', color: '#000000', textAlign: 'left', marginBottom: '14px', fontFamily: 'Quicksand' }}>{content}</Typography>)
                            }

                            {
                                details?.variant_names && details?.variant_names.map(v => (
                                    <Box sx={{ display: 'flex', marginBottom: '24px' }}>
                                        <Typography sx={{ width: '80px', fontSize: '16px', color: '#000000', fontFamily: 'Quicksand' }}>{v.title}</Typography>
                                        {
                                            v.variant_values.map(val => <Box className='chip' sx={{
                                                backgroundColor: val.isSelected ? '#000000' : '#FFFFFF', color: val.isSelected ? '#FFFFFF' : '#000000', paddingX: '16px', paddingY: '4px', border: '1px solid #000000', marginRight: '14px'
                                            }} onClick={() => handleClickVariant(v.title, val.id, val, v.id)}>
                                                <Typography>{val.title}</Typography>
                                            </Box>)
                                        }
                                    </Box>

                                ))
                            }

                            <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px', color: '#000000', textAlign: 'left', marginBottom: '12px', fontFamily: 'Quicksand' }}>Product Details</Typography>
                            <div style={{ color: '#79747E' }} dangerouslySetInnerHTML={{ __html: description }}>

                            </div>
                            {/* <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '20px', color: '#79747E', textAlign: 'left', marginBottom: '12px' }}>{details?.product_description}</Typography> */}

                            <Box sx={{ marginTop: '48px' }}>

                            </Box>
                            <Grid container spacing={{ md: 3, xs: 2 }} >
                                <Grid item md={6} xs={6}>
                                    {
                                        // details?.stock[0]?.number_44of_stocks === 0 ? '' : 

                                    }
                                    <AddToBagButtonBigRetro
                                        product={product}
                                        stock={stock}

                                    ></AddToBagButtonBigRetro>

                                </Grid>

                                <Grid item md={6} xs={6}>
                                    {
                                        carts[0] ? (<GoToBagRetro></GoToBagRetro>) : (<BuyNowButtonBigRetro></BuyNowButtonBigRetro>)
                                    }
                                </Grid>

                            </Grid>


                        </Grid>
                    </Grid>
                    <hr />
                    <Box sx={{ marginBottom: '48px' }}>

                        {
                            details?.category_id && <YouMayAlsoLikeRetro shopId={shop?.shop_id} categoryId={details?.category_id}></YouMayAlsoLikeRetro>
                        }

                        {

                            // details?.category_id & shop?.shop_id ? (<YouMayAlsoLike shopId={shop?.shop_id} categoryId={details?.category_id}></YouMayAlsoLike>) : ''

                        }
                    </Box>

                    <hr />

                </Container>


            </Box>
            <FooterRetro />

        </>
    );
};

export default ProductDetailsRetro;