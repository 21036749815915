import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import Footer from '../HomeSolo/Footer';
import axios from '../../../InstanceAxios/InstanceAxios';
import { Container } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';

const AboutUsSolo = () => {
    const shop = useSelector((state) => state.product.shop);
    const [shopData, setShopData] = useState({});
    const [willQuickLinkAppear, setWillQuickLinkAppear] = useState(false);

    const getShopData = async () => {
        try {
            const response = await axios.get(`/merchants/getShopData/${shop?.shop_link}`);
            setShopData(response.data);
            if (response.data?.about_us?.length || response.data?.terms_and_conditions?.length) {
                setWillQuickLinkAppear(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getShopData();
    }, []);


    return (
        <>
            <Box
                sx={{
                    padding: '16px',
                }}
            >
                <img
                    src={shop.shop_image}
                    alt="banner"
                    height="40px"
                />
            </Box>
            <Container
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block'
                    }
                }}
            >
                <Box
                    style={{
                        minHeight: '40vh',
                        textAlign: 'justify',   
                        marginTop: '20px',
                        fontFamily: 'Belleza',
                    }}
                    dangerouslySetInnerHTML={{ __html: shopData?.about_us }}
                ></Box>
            </Container>
            <Footer />
        </>
    );
};

export default AboutUsSolo;