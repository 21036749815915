import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const BuyNowButtonBigRetro = (props) => {

    const cart = useSelector((state) => state?.cart)


    let shop = useSelector((state) => state?.product?.shop)


    shop = JSON.parse(localStorage.getItem('shop'));

    const cartItems = cart?.cartItems;
    // console.log('cartItems', cartItems);
    const carts = cartItems?.filter(cartItem => cartItem?.shop_id === shop?.shop_id)
    // const carts = cartItems?.filter(cartItem => console.log(shop?.shop_id))
    // console.log('carts', carts);


    const name = process.env.React_App_NAME

    const hostName = window.location.hostname;


    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleClick = () => {
        carts?.length == 0 ? (
            // dispatch(addToCart(product))
            alert('please add a product')
        ) : (hostName == name ? navigate(`/${shop?.shop_link}/bagInfo`) : navigate(`/bagInfo`))



    }




    const ColorButton = styled(Button)(({ theme }) => ({
        '&:active': {
            backgroundColor: '#000000',
            fontFamily: 'Quicksand',
        },
        '&:hover': {
            backgroundColor: '#000000',
            color: 'white'
        },

    }));


    return (
        <ColorButton
            size="small"
            onClick={handleClick}
            sx={{ textTransform: 'none', backgroundColor: '#000000', border: '1px solid #000000', fontSize: '18px', fontWeight: '400', height: '48px', width: '100%', color: '#FFFFFF', fontFamily: 'Montserrat' }}
        >Buy Now

        </ColorButton>
    );
};

export default BuyNowButtonBigRetro;