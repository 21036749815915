import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import PhoneIcon from './../../../images/logo/Solo/phone.png';
import MailIcon from './../../../images/logo/Solo/mail.png';
import FacebookIcon from './../../../images/logo/Solo/facebook.png';
import InstagramIcon from './../../../images/logo/Solo/instagram.png';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../InstanceAxios/InstanceAxios';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';

const Footer = () => {
    const [shopData, setShopData] = React.useState([]);
    const [willQuickLinkAppear, setWillQuickLinkAppear] = React.useState(false);
    const shop = useSelector((state) => state.product.shop);

    const hostName = window.location.hostname;
    const name = process.env.React_App_NAME;

    const getShopData = async () => {
        try {
            const response = await axios.get(`/merchants/getShopData/${shop?.shop_link}`);
            setShopData(response.data);
            if (response.data?.about_us?.length || response.data?.terms_and_conditions?.length) {
                setWillQuickLinkAppear(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getShopData();
    }, []);

    return (
        <Container
            sx={{
                textAlign: 'left',
                padding: { xs: '32px 5%', md: '48px 0' }
            }}
        >
            <Grid container>
                <Grid item xs={12} md={willQuickLinkAppear ? 4 : 6}
                    sx={{
                        mb: { xs: '16px', md: '0px' },
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: '14px', md: '24px' },
                            fontFamily: 'Belleza',
                            textAlign: 'left',
                        }}
                    >
                        Contact us
                    </Typography>
                    {
                        shop?.phone &&
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: { xs: '4px', md: '16px' }
                            }}
                        >
                            <img src={PhoneIcon}
                                alt="phone"
                                height='14px'
                                width='14px'
                            />
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontFamily: 'Belleza',
                                    color: '#79747E',
                                    ml: '8px',
                                }}
                            >
                                {shop?.phone}
                            </Typography>
                        </Box>
                    }
                    {
                        shop?.email &&
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: { xs: '4px', md: '16px' }
                            }}
                        >
                            <img src={MailIcon}
                                alt="mail"
                                height='14px'
                                width='14px'
                            />
                            <Typography

                                sx={{
                                    fontSize: '16px',
                                    fontFamily: 'Belleza',
                                    color: '#79747E',
                                    ml: '8px',
                                }}
                            >
                                {shop?.email}
                            </Typography>
                        </Box>
                    }
                </Grid>
                {
                    willQuickLinkAppear &&
                    <Grid item xs={12} md={4}
                        sx={{
                            mb: { xs: '16px', md: '0px' },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { xs: '14px', md: '24px' },
                                fontFamily: 'Belleza',
                                textAlign: 'left',
                            }}
                        >
                            Quick links
                        </Typography>
                        {
                            shopData?.about_us?.length > 0 &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: { xs: '4px', md: '16px' }
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontFamily: 'Belleza',
                                        color: '#79747E',
                                        ml: '8px',
                                    }}
                                >
                                    <Link
                                        to={
                                            hostName == name ?
                                                `/${shop?.shop_link}/aboutUsSolo`
                                                :
                                                '/aboutUsSolo'
                                        }
                                        style={{
                                            textDecoration: 'none',
                                            color: '#79747E',
                                        }}
                                    >
                                        About us
                                    </Link>
                                </Typography>
                            </Box>
                        }
                        {
                            shopData?.terms_and_conditions?.length > 0 &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: { xs: '4px', md: '16px' }
                                }}
                            >
                                <Typography

                                    sx={{
                                        fontSize: '16px',
                                        fontFamily: 'Belleza',
                                        color: '#79747E',
                                        ml: '8px',
                                    }}
                                >
                                    <Link
                                        to={
                                            hostName == name ?
                                                `/${shop?.shop_link}/termsAndConditionsSolo`
                                                :
                                                '/termsAndConditionsSolo'
                                        }
                                        style={{
                                            textDecoration: 'none',
                                            color: '#79747E',
                                        }}
                                    >
                                        Terms and conditions
                                    </Link>
                                </Typography>
                            </Box>
                        }
                    </Grid>
                }
                {
                    shop?.facebook_link || shop?.instagram_link &&
                    <Grid item xs={12} md={willQuickLinkAppear ? 4 : 6}>
                        <Typography
                            sx={{
                                mb: '16px',
                                fontSize: { xs: '14px', md: '24px' },
                                fontFamily: 'Belleza',
                                textAlign: 'left',
                            }}
                        >
                            Follow  us
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {
                                shop?.facebook_link &&
                                <Box
                                    sx={{
                                        backgroundColor: '#D1D1D1',
                                        borderRadius: '50%',
                                        height: '30px',
                                        width: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => window.open(`//${shop?.facebook_link}`, '_blank')}
                                >
                                    <img src={FacebookIcon}
                                        alt="facebook"
                                        height='14px'
                                        width='14px'
                                    />
                                </Box>
                            }
                            {
                                shop?.instagram_link &&
                                <Box
                                    sx={{
                                        backgroundColor: '#D1D1D1',
                                        borderRadius: '50%',
                                        height: '30px',
                                        width: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        ml: '16px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => window.open(`//${shop?.instagram_link}`, '_blank')}
                                >
                                    <img src={InstagramIcon}
                                        alt="instagram"
                                        height='14px'
                                        width='14px'
                                        sx={{
                                            ml: '10px',
                                        }}
                                    />
                                </Box>
                            }
                        </Box>
                    </Grid>
                }
            </Grid>
            <Box
                sx={{
                    textAlign: 'center',
                    mt: { xs: '32px', md: '96px' }
                }}
            >
                <Typography
                    sx={{
                        display: 'inline-block',
                        fontSize: '12px',
                        fontFamily: 'Belleza',
                        color: '#79747E',
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                            color: 'blue',
                        }
                    }}
                    onClick={() => window.open('https://www.bonikapp.com/', '_blank')}
                >
                    Powered by Bonik
                </Typography>
            </Box>
        </ Container>
    );
};

export default Footer;