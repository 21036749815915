import { Avatar, Box, Button, Container, Grid, IconButton, Stack, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import BagRetro from '../../BagRetro/BagRetro/BagRetro';
import axios from '../../../../InstanceAxios/InstanceAxios'
import './NavBarRetro.css'

const useStyles = makeStyles({
    bigText: {

        color: '#021F3F',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',


    },
    bigTextRes: {

        color: '#021F3F',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'left'

    },
    smText: {

        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '12px',
        textAlign: 'left',
        display: 'flex',
        cursor: 'pointer'


    },
    smTextRes: {

        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '0px',
        textAlign: 'left',
        cursor: 'pointer'


    },
    iconStyle: {
        width: '13.47px',
        color: '#79747E',
        marginRight: '10px'

    },
    socialMedia: {
        height: '30px',
        width: '30px',
        color: '#FFFFFF',
        paddingTop: '6px',
        paddingBottom: '6px',
        backgroundColor: '#D1D1D1',
        border: '1px solid #D1D1D1',
        borderRadius: '15px',
        marginRight: '12px',

    },

    poweredByLink: {
        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        paddingBottom: '48px',
        textDecoration: 'none',
        '&:hover': {
            color: '#FF6550'
        }
    }

});



const NavBarRetro = () => {


    const [categories, setCategories] = useState([])
    let shop = useSelector((state) => state?.product?.shop)


    const navigate = useNavigate();
    const name = process.env.React_App_NAME

    const hostName = window.location.hostname;
    const classes = useStyles();


    const url = `/categories/allCategories?shop_id=${shop?.shop_id}`
    useEffect(() => {

        const fetchData = async () => {

            const response = await axios.get(url)
            // console.log(response.data);
            setCategories(response.data)
        }
        fetchData()

    }, [shop?.shop_id])


    const handleClickCollections = () => {
        // console.log('clicked');
        display === 'none' ? setDisplay('block') : setDisplay('none')
    }

    const handleClick = id => {
        hostName == name ? navigate(`/${shop?.shop_link}/categoryDetails/${id}`) : navigate(`/categoryDetails/${id}`)
    }

    const handleClickTitle = () => {
        hostName == name ? navigate(`/${shop?.shop_link}`) : navigate('/')
    }

    const handleMenuClick = () => {
        hostName == name ? navigate(`/${shop?.shop_link}/menu`) : navigate(`/menu`)
    }


    return (
        <>
            <Box sx={{ backgroundColor: '#FFFFFF', position: 'fixed', top: 0, zIndex: 999, width: '100%' }}>
                <Container>

                    <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', margin: '20px 0' }}>

                        <Box sx={{ display: 'flex' }}>

                            {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mr: '14px', ml: '150px', display: { xs: 'none', md: 'flex', } }}> */}
                            <IconButton sx={{ p: 0, mr: '16px', border: '1px solid #000000', display: { xs: 'none', md: 'flex' } }}>
                                <Avatar alt="Shop Name" src={shop?.shop_image} />

                            </IconButton>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                onClick={handleClickTitle}
                                sx={{ mr: 2, color: '#000000', cursor: 'pointer', fontSize: '24px', display: { md: 'block', xs: 'none' }, fontFamily: 'Quicksand' }}
                            >
                                {shop?.shop_name}
                            </Typography>
                        </Box>
                        <Box>


                            <Stack direction="row" alignItems="center" spacing={4} >
                                {/* <NavLink to='/bag' style={{ textDecoration: 'none' }}> */}
                                <Box className='dropdown'>
                                    <Button className='dropdown-button' sx={{ textTransform: 'none', color: '#000000', fontSize: '16px', fontWidth: 500, lineHeight: '24px', fontFamily: 'Quicksand' }} >Collections</Button>

                                    <Box className='dropdown-content' sx={{ width: '120px', position: 'absolute', backgroundColor: '#FFFFFF', paddingLeft: '16px', float: 'none', paddingTop: '16px' }}>

                                        {
                                            categories?.Product_Categories?.map(category => <><p key={category?.category_id} className={classes.smTextRes} onClick={() => handleClick(category?.category_id)}> {category?.category}</p><br /></>)
                                        }


                                    </Box>
                                </Box>



                                <BagRetro></BagRetro>


                            </Stack>


                        </Box>

                    </Box>
                </Container>

            </Box>


            {/* manueIcon */}

            <Container maxWidth="xl" sx={{ backgroundColor: '#FFFFFF', position: 'fixed', top: 0, zIndex: 999 }}>


                <Box sx={{ display: { xs: 'flex', md: 'none' }, paddingRight: '35px' }}>
                    <Grid container >
                        <Grid item xs={10} >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenuClick}
                                    // onClick={handleOpenNavMenu}
                                    sx={{ paddingLeft: '0px' }}
                                    color="inherit"
                                >
                                    <MenuIcon sx={{ color: '#000000', width: '6.667vw', height: '5.333vw' }} />
                                </IconButton>

                                <Box sx={{ width: '49.600vw', height: '56px', display: 'flex', alignItems: 'center' }}>

                                    {/* LogoRes */}

                                    <Typography
                                        variant="h6"
                                        onClick={handleClickTitle}
                                        component="div"
                                        sx={{ color: '#000000', fontSize: '4.8vw', fontFamily: 'Quicksand' }}
                                    >
                                        {shop?.shop_name}
                                    </Typography>
                                </Box>

                            </Box>

                        </Grid>
                        {/* <Grid item xs={0}>

                        </Grid> */}
                        <Grid item xs={2} >
                            <Grid container sx={{ display: 'flex', alignItems: 'center', height: '56px' }}>

                                <Grid item xs={6}>
                                    {/* <img style={{ color: '#FFFFFF', width: '6.667vw', height: '6.667vw' }} src={search} alt="" /> */}
                                </Grid>
                                <Grid item xs={6}>



                                    <BagRetro></BagRetro>


                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                </Box>

            </Container>
        </>
    );
};

export default NavBarRetro;