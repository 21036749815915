import { Box, Container } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../../InstanceAxios/InstanceAxios'
import FooterRetro from '../SharedRetro/FooterRetro/FooterRetro';
import NavBarRetro from '../SharedRetro/NavBarRetro/NavBarRetro';

const TermsAndConditionsRetro = () => {
    const [shopData, setShopData] = React.useState([]);
    const shop = useSelector((state) => state.product.shop);

    const getShopData = async () => {
        try {
            const response = await axios.get(`/merchants/getShopData/${shop?.shop_link}`);
            setShopData(response.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getShopData();
    }, [shop?.shop_link]);
    return (
        <>
            <NavBarRetro />
            <Container>
                <Box
                    style={{
                        minHeight: '40vh',
                        textAlign: 'justify',
                        marginTop: '100px',
                    }}
                    dangerouslySetInnerHTML={{ __html: shopData?.terms_and_conditions }}
                ></Box>
            </Container>
            <FooterRetro />
        </>
    );
};

export default TermsAndConditionsRetro;