import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Testimonial from './Testimonial';
import axios from '../../../InstanceAxios/InstanceAxios';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'

// function Item(props)
// {
//     return (
//         <Paper>
//             <h2>{props.item.name}</h2>
//             <p>{props.item.description}</p>

//             <Button className="CheckButton">
//                 Check it out!
//             </Button>
//         </Paper>
//     )
// }

const Testimonials = ({ solo_theme_product_id }) => {
    const [testimonials, setTestimonials] = useState([]);

    const getAllTestimonials = async () => {
        try {
            const url = `/soloTheme/getAllTestimonials/${solo_theme_product_id}`;
            const response = await axios.get(url);
            setTestimonials(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getAllTestimonials();
    }, [solo_theme_product_id]);

    return (
        <>
            {
                testimonials.length > 0 &&
                <Box
                    sx={{
                        backgroundColor: '#F8F8F8',
                        padding: '56px 0',
                    }}
                >
                    <Carousel>
                        {
                            testimonials.map((testimonial, i) => <Testimonial key={i} testimonial={testimonial} />)
                        }
                    </Carousel>
                </Box >
            }
        </>
    );
};

export default Testimonials;