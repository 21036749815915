import { AppBar, Box, Container, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import viewMore from '../../../../images/logo/viewmore_r.svg';
import axios from '../../../../InstanceAxios/InstanceAxios';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles({
    bigText: {

        color: '#000000',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',


    },
    bigTextRes: {

        color: '#000000',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'left'

    },
    smText: {

        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '12px',
        textAlign: 'left',
        display: 'flex',
        cursor: 'pointer'


    },
    smTextRes: {

        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '0px',
        textAlign: 'left'


    },
    iconStyle: {
        width: '13.47px',
        color: '#79747E',
        marginRight: '10px'

    },
    socialMedia: {
        height: '30px',
        width: '30px',
        color: '#FFFFFF',
        paddingTop: '6px',
        paddingBottom: '6px',
        backgroundColor: '#D1D1D1',
        border: '1px solid #D1D1D1',
        borderRadius: '15px',
        marginRight: '12px',

    },

    poweredByLink: {
        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        paddingBottom: '48px',
        textDecoration: 'none',
        '&:hover': {
            color: '#000000'
        }
    }

});

const MenuRetro = () => {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const [display, setDisplay] = useState('none')
    const [categories, setCategories] = useState([])

    const shopLink = params?.shop_link


    const name = process.env.React_App_NAME

    const hostName = window.location.hostname;


    let shop = useSelector((state) => state?.product?.shop)

    const url = `/categories/allCategories?shop_id=${shop?.shop_id}`
    useEffect(() => {

        const fetchData = async () => {

            const response = await axios.get(url)
            // console.log(response.data);
            setCategories(response.data)
        }
        fetchData()

    }, [shop?.shop_id])



    const navigate = useNavigate();

    const handleClickTitle = () => {
        hostName == name ? navigate(`/${shop?.shop_link}`) : navigate('/')
    }

    const handleClick = id => {
        hostName == name ? navigate(`/${shop?.shop_link}/categoryDetails/${id}`) : navigate(`/categoryDetails/${id}`)
    }

    const handleClearClick = () => {

        navigate(-1)

    }

    const handleClickCollections = () => {
        // console.log('clicked');
        display === 'none' ? setDisplay('block') : setDisplay('none')
    }
    const handleClickHome = () => {
        // console.log('clicked');
        hostName == name ? navigate(`/${shop?.shop_link}`) : navigate("/")
    }

    return (
        <>
            <AppBar position="fixed" sx={{ boxShadow: 'none', height: '56px' }}>
                <Container maxWidth="xl" sx={{ backgroundColor: '#FFFFFF' }}>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        <Grid container>
                            <Grid item md={4}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        // onClick={handleOpenNavMenu}
                                        sx={{ paddingLeft: '0px' }}

                                    >
                                        <ClearIcon onClick={handleClearClick} sx={{ color: '#000000' }} />
                                    </IconButton>


                                    {/* LogoRes */}
                                    <Box sx={{ width: '49.600vw', height: '56px', display: 'flex', alignItems: 'center' }}>
                                        <Typography
                                            variant="h6"
                                            onClick={handleClickTitle}
                                            component="div"
                                            sx={{ mr: 2, color: '#000000', fontSize: '4.8vw', fontFamily: 'Quicksand' }}
                                        >
                                            {shop?.shop_name}
                                        </Typography>

                                    </Box>


                                </Box>
                            </Grid>


                        </Grid>
                    </Box>
                </Container>
            </AppBar>
            <Container sx={{ marginTop: '56px' }}>
                <Box onClick={handleClickHome} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginY: '0px', color: '#000000', fontWeight: 500, fontSize: '16px' }}>
                    <p style={{ marginTop: '12px' }}>Home</p>
                    <ArrowForwardIosIcon sx={{ width: '16px' }} />
                </Box>
                <hr style={{ marginTop: '0px', marginBottom: '0px' }} />
                <Box onClick={handleClickCollections} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginY: '0px', color: '#000000', fontWeight: 500, fontSize: '16px' }}>
                    <p style={{ marginTop: '12px' }}>Collections</p>

                    <img src={viewMore}
                    />
                </Box>
                <Box sx={{ display: { display } }}>

                    {
                        categories?.Product_Categories?.map(category => <><p key={category?.category_id} className={classes.smTextRes} onClick={() => handleClick(category?.category_id)}> {category?.category}</p><br /></>)
                    }


                </Box>

            </Container>
        </>
    );
};

export default MenuRetro;