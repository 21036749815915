import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ShopNowButtonRetro = ({ product_id }) => {

    const navigate = useNavigate();

    let shop = useSelector((state) => state?.product?.shop)


    shop = JSON.parse(localStorage.getItem('shop'));
    const name = process.env.React_App_NAME
    const hostName = window.location.hostname;

    const handleClick = () => {
        // console.log(product_id);
        hostName == name ? navigate(`/${shop?.shop_link}/products/${product_id}`) : navigate(`/products/${product_id}`)
    }

    const ColorButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        border: '1px solid #000000',
        objectFit: 'cover',
        width: '100%',
        borderRadius: '0px',
        fontSize: '14px',
        fontWeight: 'normal',
        height: '32px',
        backgroundColor: '#000000',
        paddingLeft: '15px',
        paddingRight: '15px',
        fontFamily: 'Quicksand',
        height: '45px',
        color: '#FFFFFF',
        '&:active': {
            backgroundColor: '#000000',
        },
        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: '#000000'
        },

    }));

    return (
        <ColorButton
            size="small"
            onClick={handleClick}

        >Shop Now

        </ColorButton>
    );
};

export default ShopNowButtonRetro;