import { Badge, Box, Button, Container, Drawer, Grid, IconButton, Typography } from '@mui/material';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import React, { Fragment, useEffect, useState } from 'react';
import BagProduct from '../BagProduct/BagProduct';
import Calculate from '../Calculate/Calculate';
import { getStoredCart } from '../../../../utilities/fakeDb';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BuyNowButton from '../../Shared/Button/BuyNowButton/BuyNowButton';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";

import { getTotals } from '../../../../redux/slices/cartSlice';
// import { sendValue } from '../../Shared/Button/AddToBagButton/AddToBagButton';



// export const click = () => {

//     const clicked
// }
// console.log('bag bag', clicked);

const Bag = () => {
    let quantity = 0;
    const dispatch = useDispatch()

    const [state, setState] = useState(false);


    let shop = useSelector((state) => state?.product?.shop)
    const navigate = useNavigate();

    shop = JSON.parse(localStorage.getItem('shop'));

    // console.log('shop', shop);
    const cart = useSelector((state) => state?.cart)
    const cartItems = cart?.cartItems;
    // console.log('cartItems', cartItems);
    const carts = cartItems?.filter(cartItem => cartItem?.shop_id === shop?.shop_id)
    // const carts = cartItems?.filter(cartItem => console.log(shop?.shop_id))
    // console.log('carts', carts);


    const name = process.env.React_App_NAME;

    const hostName = window.location.hostname;


    carts.map(cart => quantity += cart.quantity)
    // console.log(quantity);

    let total = 0;
    let totalSellingPrice = 0;

    carts.map(cart => {
        let itemTotal;
        const { unit_selling_price, quantity, unit_discounted_price } = cart;
        const itemTotalSellingPrice = unit_selling_price * quantity;
        unit_discounted_price ? (itemTotal = unit_discounted_price * quantity) : (itemTotal = unit_selling_price * quantity)

        totalSellingPrice += itemTotalSellingPrice
        total += itemTotal

    })


    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };

    const handleClick = () => {
        hostName == name ? navigate(`/${shop?.shop_link}/bag`) : navigate(`/bag`)

    }

    const ColorButtonEmptyBag = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        border: '1px solid #FF6550',
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 'normal',
        height: '45px',
        width: '100%',
        color: '#FFFFFF',
        backgroundColor: '#FF6550',
        fontFamily: 'Montserrat',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: '#FF6550'
        },



    }));

    const ColorButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        border: '1px solid #FF6550',
        borderRadius: '4px',
        fontSize: '0.833vw',
        fontWeight: '500',
        fontFamily: 'Montserrat',
        height: '45px',
        width: '100%',
        color: '#FF6550',



    }));

    const CheckOutButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        border: '1px solid #FF6550',
        borderRadius: '4px',
        objectFit: 'cover',
        width: '100%',
        fontSize: '0.833vw',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        height: '32px',
        backgroundColor: '#FF6550',
        height: '45px',
        color: '#FFFFFF',
        '&:hover': {
            color: '#FF6550'
        },


    }));

    const handleClickCheckOutButton = () => {
        carts?.length == 0 ? (
            // dispatch(addToCart(product))
            alert('please add a product')
        ) : (hostName == name ? navigate(`/${shop?.shop_link}/bagInfo`) : navigate(`/bagInfo`))

    }

    useEffect(() => {
        dispatch(getTotals())
    }, [])



    const list = () => (
        <Box
            sx={{ width: '32.91vw' }}
            role="presentation"

            onKeyDown={toggleDrawer(false)}
        >
            <Box onClick={toggleDrawer(false)} sx={{ marginTop: '85px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Badge badgeContent={quantity} color="error" sx={{ marginRight: '24px' }}>
                    <ShoppingBagIcon sx={{ color: ' #021F3F', width: '41px', height: '41px' }}></ShoppingBagIcon>
                </Badge>
                <Typography sx={{ fontSize: '28px', fontWeight: 500, color: ' #021F3F', fontFamily: 'Montserrat', lineHeight: '36px' }}>Your Bag</Typography>

            </Box>
            <BagProduct carts={carts}></BagProduct>

            {
                carts[0] ? (<>
                    <Box sx={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#FFFFFF' }}>
                        <hr />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '20px', color: '#021F3F', marginX: '3.75vw' }}>
                            <Grid container spacing={{ md: 2 }} columns={{ md: 6 }} >
                                <Grid item md={12} sx={{ color: '#021F3F', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, textAlign: 'center', fontFamily: 'Montserrat' }}>Total</Typography>

                                    {/* <Typography sx={{ fontSize: '16px', marginLeft: '80px', fontWeight: 500 }}>{total + totalVat}</Typography> */}
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                                        {shop?.currencies?.title}  {total}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ marginTop: '12px', width: '25%' }}>
                                <Grid item md={6}>
                                    <ColorButton
                                        size="small"
                                        onClick={toggleDrawer(false)}

                                    >Continue Shopping

                                    </ColorButton>
                                </Grid>
                                <Grid item md={6}>

                                    <CheckOutButton
                                        size="small"
                                        onClick={handleClickCheckOutButton}

                                    >Checkout

                                    </CheckOutButton>

                                </Grid>
                            </Grid>

                        </Box >
                    </Box >

                </>
                ) : (<Grid container spacing={1} sx={{ marginTop: '12px' }}>
                    <Grid item md={3}>

                    </Grid>
                    <Grid item md={6}>
                        <ColorButtonEmptyBag
                            size="small"
                            onClick={toggleDrawer(false)}

                        >Continue Shopping

                        </ColorButtonEmptyBag>
                    </Grid>
                    <Grid item md={3}>

                    </Grid>

                </Grid>)
            }


        </Box>
    );

    if (shop?.facebook_pixel_id) {
        ReactPixel.pageView();
    }
    if (shop?.google_analytics_measurement_id) {
        ReactGA.send("pageview");
    }


    return (
        <Fragment key={'right'}>
            {/* <Box onClick={toggleDrawer(true)} sx={{}}> */}
            <Button onClick={toggleDrawer(true)} sx={{ color: '#021F3F', textTransform: 'none', display: { xs: 'none', md: 'flex' }, fontFamily: 'Montserrat' }}>

                <Badge badgeContent={quantity} color="error" sx={{ marginRight: '10px' }}>

                    <ShoppingBagOutlinedIcon sx={{ color: '#021F3F', width: '25px', height: '25px' }}>

                    </ShoppingBagOutlinedIcon>
                    {/* <Box sx={{ position: 'absolute', color: '#021F3F', backgroundColor: '#FFFFFF', width: '18px', height: '14px', border: '1px solid #021F3F', borderRadius: '20px', marginTop: '10px', marginLeft: '13px' }}>
                                <Typography sx={{ fontSize: '10px', textAlign: 'center' }}>{quantity}</Typography>
                            </Box> */}

                </Badge>

            </Button>
            {/* </Box> */}

            {/* <Box sx={{}}> */}

            <Button onClick={handleClick} sx={{ color: '#FFFFFF', textTransform: 'none', padding: '0px', display: { xs: 'flex', md: 'none' } }} size="large" aria-label="show number of products">
                <Badge badgeContent={quantity} color="error" sx={{ marginRight: '10px' }}>

                    <ShoppingBagOutlinedIcon sx={{ color: '#021F3F', width: '6.667vw', height: '6.667vw' }}  >

                    </ShoppingBagOutlinedIcon>
                    {/* <Box sx={{ position: 'absolute', color: '#021F3F', backgroundColor: '#FFFFFF', width: '6.667vw', height: '4vw', border: '1px solid #021F3F', borderRadius: '5.333vw', marginTop: '2.667vw', marginLeft: '2.667vw' }}>
                            <Typography sx={{ fontSize: '2.667vw', textAlign: 'center' }}>{quantity}</Typography>
                        </Box> */}

                </Badge>
            </Button>

            {/* </Box> */}

            <Drawer
                anchor='right'
                open={state}

                onClose={toggleDrawer(false)}
            >
                {list()}
            </Drawer>

        </Fragment>
    );
};

export default Bag;