import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

import { useDispatch } from 'react-redux';
import { addToCart, decreaseCart, getTotals } from '../../../../../redux/slices/cartSlice';
import { getStoredCart } from '../../../../../utilities/fakeDb';

const AddToBagButtonBigRetro = ({ product, stock }) => {
    let disable = true
    let disableAdd = false
    const [checked, setChecked] = useState(false);

    const [value, setValue] = useState(0);

    const dispatch = useDispatch();



    let quantity = 0;
    const cart = getStoredCart('cart');
    cart.map(cartP => quantity += cartP.quantity)
    // console.log('button', product);

    useEffect(() => {
        dispatch(getTotals());
        dispatch(addToCart(product))
        dispatch(decreaseCart(product))

        // console.log(cart);
        const itemIndex = cart?.findIndex(item => item.product_id === product?.product_id && item.variant_product_id === product?.variant_product_id);
        // console.log(itemIndex);
        if (itemIndex >= 0) {
            setValue(cart[itemIndex].quantity)
            setChecked(true)

        }
        else {
            setValue(0)
            setChecked(false)
        }


        // setValue(cart[product?.product_id])



        // if (cart[product?.product_id]) {

        // }

    }, [dispatch, product?.product_id, quantity, product?.variant_product_id])

    stock ? (stock < 1 ? (disable = true) : (disable = false)) : ''

    stock ? (value >= stock && (disableAdd = true)) : ''


    // console.log(disable);

    const addToBagButton = (product) => {
        setChecked(true);
        dispatch(addToCart(product))

        // addToDb(id);
        setValue(value + 1);

    }

    const add = (product) => {

        dispatch(addToCart(product))

        // addToDb(id);
        setValue(value + 1)
    }

    const subtract = (product) => {

        dispatch(decreaseCart(product))
        // subtractFromDb(id);
        setValue(value - 1)
    }

    const ColorButton = styled(Button)(({ theme }) => ({
        color: '#000000',
        textTransform: 'none',
        border: '1px solid #000000',
        borderRadius: '0px',
        fontSize: '18px',
        fontWeight: '400',
        height: '48px',
        width: '100%',

        fontFamily: 'Quicksand',
        '&:active': {
            backgroundColor: '#000000',
        },
        '&:hover': {
            backgroundColor: '#000000',
            color: 'white'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '16px'
        }

    }));

    return (
        <>
            {checked ? (
                <ColorButton
                    size="small"
                    disabled={disable}
                    sx={{ backgroundColor: '#000000', textTransform: 'none', border: '1px solid #000000', fontSize: '18px', fontWeight: '400', height: '48px', width: '100%', color: '#FFFFFF', fontFamily: 'Quicksand' }}
                >
                    <button disabled={disable} onClick={() => subtract(product)} style={{ background: '#000000', border: 'none', color: '#FFFFFF', marginRight: '40px', fontSize: '22px' }}>-</button>
                    {value}
                    <button disabled={disableAdd} onClick={() => add(product)} style={{ background: '#000000', border: 'none', color: '#FFFFFF', marginLeft: '40px', fontSize: '22px' }}>+</button>
                </ColorButton>
            ) : (
                <ColorButton
                    size="small"
                    disabled={disable}
                    onClick={() => addToBagButton(product)}

                >
                    Add to bag
                </ColorButton>
            )}



        </>
    );
};

export default AddToBagButtonBigRetro;