import { AppBar, Box, Container, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import ClearIcon from '@mui/icons-material/Clear';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const MenuTechne = () => {

    const params = useParams();
    const dispatch = useDispatch();


    const shopLink = params?.shop_link


    const name = process.env.React_App_NAME

    const hostName = window.location.hostname;


    let shop = useSelector((state) => state?.product?.shop)



    const navigate = useNavigate();

    const handleClickTitle = () => {
        hostName == name ? navigate(`/${shop?.shop_link}`) : navigate('/')
    }

    const handleClearClick = () => {

        navigate(-1)

    }

    const handleClickCollections = () => {
        // console.log('clicked');
        hostName == name ? navigate(`/${shop?.shop_link}/collections`) : navigate(`/collections`)
    }
    const handleClickHome = () => {
        // console.log('clicked');
        hostName == name ? navigate(`/${shop?.shop_link}`) : navigate("/")
    }

    return (
        <>
            <AppBar position="fixed" sx={{ boxShadow: 'none', height: '56px' }}>
                <Container maxWidth="xl" sx={{ backgroundColor: '#FFFFFF' }}>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        <Grid container>
                            <Grid item md={4}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        // onClick={handleOpenNavMenu}
                                        sx={{ paddingLeft: '0px' }}

                                    >
                                        <ClearIcon onClick={handleClearClick} sx={{ color: '#000000' }} />
                                    </IconButton>


                                    {/* LogoRes */}
                                    <Box sx={{ width: '49.600vw', height: '56px', display: 'flex', alignItems: 'center' }}>
                                        <Typography
                                            variant="h6"
                                            onClick={handleClickTitle}
                                            component="div"
                                            sx={{ mr: 2, color: '#000000', fontSize: '4.8vw', fontFamily: 'Quicksand' }}
                                        >
                                            {shop?.shop_name}
                                        </Typography>

                                    </Box>


                                </Box>
                            </Grid>


                        </Grid>
                    </Box>
                </Container>
            </AppBar>
            <Container sx={{ marginTop: '56px' }}>
                <Box onClick={handleClickHome} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginY: '0px', color: '#000000', fontWeight: 500, fontSize: '16px' }}>
                    <p style={{ marginTop: '12px' }}>Home</p>
                    <ArrowForwardIosIcon sx={{ width: '16px' }} />
                </Box>
                <hr style={{ marginTop: '0px', marginBottom: '0px' }} />
                <Box onClick={handleClickCollections} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginY: '0px', color: '#000000', fontWeight: 500, fontSize: '16px' }}>
                    <p style={{ marginTop: '12px' }}>Collections</p>
                    <ArrowForwardIosIcon sx={{ width: '16px' }} />
                </Box>

            </Container>
        </>
    );
};

export default MenuTechne;