import { createSlice } from '@reduxjs/toolkit'




const cartSlice = createSlice({
    name: 'cart',
    initialState: {

        cartTotalQuantity: 0,
        cartTotalAmount: 0,
        cartTotalDiscountedPrice: 0,
        cartItems: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : []
    },

    reducers: {
        addToCart: (state, action) => {

            const variantItemIndex = state.cartItems.findIndex(item => item.product_id === action.payload?.product_id && item.variant_product_id !== action.payload?.variant_product_id);
            const itemIndex = state.cartItems.findIndex(item => item.product_id === action.payload?.product_id && item.variant_product_id === action.payload?.variant_product_id);


            if (itemIndex >= 0) {
                state.cartItems[itemIndex].quantity += 1
            }

            else if (variantItemIndex >= 0) {
                removeFromCart(action.payload)
                const tempProduct = { ...action.payload, quantity: 1 };
                state.cartItems.push(tempProduct);
            }

            else {
                const tempProduct = { ...action.payload, quantity: 1 };
                state.cartItems.push(tempProduct);
            }

            localStorage.setItem('cart', JSON.stringify(state.cartItems));
        },
        removeFromCart: (state, action) => {

            state.cartItems = state.cartItems.filter(product => product.product_id !== action.payload?.product_id)


        },

        decreaseCart: (state, action) => {
            const itemIndex = state.cartItems.findIndex(cartItem => cartItem.product_id === action.payload?.product_id)
            if (state.cartItems[itemIndex].quantity > 1) {
                state.cartItems[itemIndex].quantity -= 1
            }
            else if (state.cartItems[itemIndex].quantity === 1) {
                state.cartItems = state.cartItems.filter(product => product.product_id !== action.payload?.product_id)
            }
            localStorage.setItem('cart', JSON.stringify(state.cartItems));
        },

        clearCart: (state, action) => {
            // console.log('clear');
            state.cartItems = [];
            localStorage.setItem('cart', JSON.stringify(state.cartItems));
        },

        getTotals: (state, action) => {
            let itemTotal
            let { total, quantity } = state.cartItems.reduce((cartTotal, cartItem) => {
                const { unit_selling_price, quantity, unit_discounted_price } = cartItem;
                // unit_discounted_price ? (itemTotal = unit_discounted_price * quantity) : (itemTotal = unit_selling_price * quantity)
                const itemTotal = unit_selling_price * quantity


                cartTotal.total += itemTotal
                // cart.totalSellingPrice += totalSellingPrice
                cartTotal.quantity += quantity
                return cartTotal;
            }, {
                total: 0,
                // totalSellingPrice: 0,
                quantity: 0
            })
            state.cartTotalQuantity = quantity;
            state.cartTotalAmount = total;
            // state.cartTotalSellingPrice = totalSellingPrice;
        },

    },

})

export const { addToCart, removeFromCart, decreaseCart, clearCart, getTotals } = cartSlice.actions;

export default cartSlice.reducer;