import { alpha, Avatar, Badge, Box, Button, Container, Grid, IconButton, InputBase, Menu, MenuItem, Stack, styled, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';


import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Bag from '../../Bag/Bag/Bag';
import trackYourOrder from '../../../../images/logo/track_your_order.svg'
import search from '../../../../images/logo/search.svg'

import { useSelector } from 'react-redux';


const pages = [];
const settings = [];




const Navigation = () => {


    let shop = useSelector((state) => state?.product?.shop)


    shop = JSON.parse(localStorage.getItem('shop'));
    const navigate = useNavigate();
    const name = process.env.React_App_NAME;

    const hostName = window.location.hostname;


    // console.log(shop);

    // const handleOpenNavMenu = (event) => {
    //     setAnchorElNav(event.currentTarget);
    // };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        color: '#95989A',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',

        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        border: '1px solid #E6E6E6',
        borderRadius: '4px',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        height: '40px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: '452px',
        },
    }));


    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'black',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,

            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
    }));



    const handleClickNewArrivals = () => {
        // console.log('clicked');
        hostName == name ? navigate(`/${shop?.shop_link}/newArrival`) : navigate(`/newArrival`)
    }
    const handleClickAllProducts = () => {
        // console.log('clicked');
        hostName == name ? navigate(`/${shop?.shop_link}/allProducts`) : navigate(`/allProducts`)
    }

    const handleClickCollections = () => {
        // console.log('clicked');
        hostName == name ? navigate(`/${shop?.shop_link}/collections`) : navigate(`/collections`)
    }

    const handleClickTitle = () => {
        hostName == name ? navigate(`/${shop?.shop_link}`) : navigate('/')
    }

    const handleMenuClick = () => {
        hostName == name ? navigate(`/${shop?.shop_link}/menu`) : navigate(`/menu`)
    }



    return (
        <>
            <Box sx={{ backgroundColor: '#FFFFFF', position: 'fixed', top: 0, zIndex: 999, width: '100%' }}>
                <Container>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', margin: '20px 0' }}>
                        <Box sx={{ display: 'flex' }}>
                            {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mr: '14px', ml: '150px', display: { xs: 'none', md: 'flex', } }}> */}
                            <IconButton sx={{ p: 0, mr: '16px', border: '1px solid #021F3F', display: { xs: 'none', md: 'flex', } }}>
                                <Avatar alt="Shop Name" src={shop?.shop_image} />

                            </IconButton>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                onClick={handleClickTitle}
                                sx={{ mr: 2, color: '#021F3F', cursor: 'pointer', fontSize: '24px', display: { md: 'block', xs: 'none' }, fontFamily: 'Montserrat' }}
                            >
                                {shop?.shop_name}
                            </Typography>
                        </Box>

                        <Stack direction="row" alignItems="center" spacing={4}>
                            {/* <NavLink to='/bag' style={{ textDecoration: 'none' }}> */}

                            <Button sx={{ textTransform: 'none', color: '#021F3F', fontSize: '16px', fontWidth: 500, lineHeight: '24px', fontFamily: 'Montserrat' }} onClick={handleClickAllProducts}>All Product</Button>
                            <Button sx={{ textTransform: 'none', color: '#021F3F', fontSize: '16px', fontWidth: 500, lineHeight: '24px', fontFamily: 'Montserrat' }} onClick={handleClickCollections}>Collections</Button>
                            <Button sx={{ textTransform: 'none', color: '#021F3F', fontSize: '16px', fontWidth: 500, lineHeight: '24px', fontFamily: 'Montserrat' }} onClick={handleClickNewArrivals}>New Arrivals</Button>

                            <Bag></Bag>


                        </Stack>

                    </Box>
                </Container>
            </Box>
            {/* manueIcon */}
            <Container maxWidth="xl" sx={{ backgroundColor: '#FFFFFF', position: 'fixed', top: 0, zIndex: 999 }}>


                <Box sx={{ display: { xs: 'flex', md: 'none' }, paddingRight: '35px' }}>
                    <Grid container >
                        <Grid item xs={10} >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenuClick}
                                    // onClick={handleOpenNavMenu}
                                    sx={{ paddingLeft: '0px' }}
                                    color="inherit"
                                >
                                    <MenuIcon sx={{ color: '#021F3F', width: '6.667vw', height: '5.333vw' }} />
                                </IconButton>

                                <Box sx={{ width: '49.600vw', height: '56px', display: 'flex', alignItems: 'center' }}>

                                    {/* LogoRes */}

                                    <Typography
                                        variant="h6"
                                        onClick={handleClickTitle}
                                        component="div"
                                        sx={{ color: '#021F3F', fontSize: '4.8vw', fontFamily: 'Montserrat' }}
                                    >
                                        {shop?.shop_name}
                                    </Typography>
                                </Box>

                            </Box>

                        </Grid>
                        {/* <Grid item xs={0}>

                                </Grid> */}
                        <Grid item xs={2} >
                            <Grid container sx={{ display: 'flex', alignItems: 'center', height: '56px' }}>

                                <Grid item xs={6}>
                                    {/* <img style={{ color: '#FFFFFF', width: '6.667vw', height: '6.667vw' }} src={search} alt="" /> */}
                                </Grid>
                                <Grid item xs={6}>
                                    <Bag></Bag>
                                </Grid>
                            </Grid>
                            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                                    </Box> */}
                        </Grid>

                    </Grid>
                </Box>

            </Container>
        </>
    );
};

export default Navigation;


{/* <img style={{ color: '#FFFFFF', width: '35px', height: '25px', marginTop: '22px' }} src={trackYourOrder} alt="" /> */ }
{/* <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu> */}



{/* search bar */ }
{/* <Box sx={{ ml: '40px', width: '608px', height: '40px', display: { xs: 'none', md: 'flex' } }}> */ }
{/* <Search sx={{ backgroundColor: '#FFFFFF' }}>
                                <SearchIconWrapper>
                                    <SearchIcon sx={{ color: ' #D1D1D1' }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    sx={{ color: ' #D1D1D1' }}
                                    placeholder="Search Product"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </Search> */}

{/* </Box> */ }



{/* </NavLink> */ }
{/* <Box sx={{ backgroundColor: '#FFFFFF', height: '30px', width: '1px', marginTop: '22px' }}></Box> */ }

{/* track your order */ }
{/* <MenuItem sx={{ color: '#FFFFFF' }}>
                                <IconButton size="large" aria-label="">

                                    <img style={{ color: '#FFFFFF', width: '25px', height: '25px' }} src={trackYourOrder} alt="" />

                                </IconButton>
                                <p style={{ fontSize: '16px', marginTop: '15px' }}>track Your Order</p>
                            </MenuItem> */}



{/* <Box sx={{ flexGrow: 0 }}>

                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box> */}
