import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Container, Grid } from '@mui/material';
import axios from '../../../../InstanceAxios/InstanceAxios'
import NavBarRetro from '../../SharedRetro/NavBarRetro/NavBarRetro';

const useStyles = makeStyles({
    img: {

        objectFit: 'cover',
        width: '100%',
        height: '338px',
    },
    imgRes: {

        objectFit: 'cover',
        width: '100%',
        height: '140px',
    },
    title: {

        marginBottom: '32px',
        fontWeight: 400,
        fontSize: '22px',
        lineHeight: '28px',
        color: '#000000',
    },
    centered: {
        position: 'absolute',
        marginTop: '-169px',
        marginLeft: '8.5%',
        transform: 'translate(-50%, -50%)',
        color: '#FFFFFF',
        fontSize: '22px'
    },
    centeredRes: {
        position: 'absolute',
        marginTop: '-70px',
        marginLeft: '21%',
        transform: 'translate(-50%, -50%)',
        color: '#FFFFFF'
    },
    blur: {
        background: 'rgba(102, 102, 102, 0.7)',
        backdropFilter: 'blur(0px)',
        width: '100%',
        height: '338px',
        marginTop: '-338px',
        cursor: 'pointer',
    },
    blurRes: {
        background: 'rgba(102, 102, 102, 0.7)',
        backdropFilter: 'blur(0px)',
        width: '100%',
        height: '140px',
        marginTop: '-140px',
        cursor: 'pointer',
    }

});

const CollectionsPageRetro = () => {


    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const [allCategories, setAllCategories] = useState([])
    let shop = useSelector((state) => state?.product?.shop)
    // shop = JSON.parse(localStorage.getItem('shop'));

    const shopLink = params?.shop_link

    const name = process.env.React_App_NAME
    const hostName = window.location.hostname;

    // useEffect(() => {
    //     // console.log('shopLink', shopLink);
    //     hostName == name ?
    //         (shop?.shop_link !== shopLink && dispatch(fetchShop(shopLink)))
    //         : (shop?.shop_domain !== hostName && dispatch(fetchShop(hostName)))
    // }, [dispatch])
    // shop = useSelector((state) => state?.product?.shop)


    const url = `/productCategories/all?shop_id=${shop?.shop_id}`
    useEffect(() => {

        const fetchData = async () => {
            const response = await axios.get(url)
            setAllCategories(response.data)

        }

        fetchData()

    }, [shop?.shop_id])
    // console.log(allCategories?.Product_Categories);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleClick = (id) => {
        // console.log('specific collection', id);

        hostName == name ? navigate(`/${shop?.shop_link}/categoryDetails/${id}`) : navigate(`/categoryDetails/${id}`)
    }


    return (
        <>
            <NavBarRetro />
            <Container>


                <Box sx={{ display: { md: 'block', xs: 'none' }, marginTop: '112px', objectFit: 'cover' }}>
                    <p className={classes.title}>Our Collections</p>
                    {
                        allCategories?.Product_Categories ? (<Grid container spacing={2}>

                            {
                                allCategories?.Product_Categories?.map(categories => <Grid key={categories?.category_id} items md={3}
                                    onClick={() => { handleClick(categories?.category_id) }}>
                                    <Box sx={{ marginRight: '24px', marginBottom: '24px' }}>
                                        <img className={classes.img} src={categories?.img_url} />
                                        <div className={classes.blur}></div>
                                        <p className={classes.centered}>{categories?.category}</p>
                                    </Box>
                                </Grid>)
                            }


                        </Grid>) : (<CircularProgress
                            sx={{ color: '#000000' }}
                            size={40}
                            thickness={4} />)
                    }


                </Box>
                <Box sx={{ display: { md: 'none', xs: 'block' }, marginTop: '66px' }}>
                    <p className={classes.title}>Our Collections</p>
                    {
                        allCategories?.Product_Categories ? (<Grid container>


                            {
                                allCategories?.Product_Categories?.map(categories => <Grid key={categories?.category_id} items xs={6}
                                    onClick={() => { handleClick(categories?.category_id) }}>
                                    <Box sx={{ marginRight: '8px', marginBottom: '24px', marginLeft: '8px' }}>
                                        <img className={classes.imgRes} src={categories?.img_url} />
                                        <div className={classes.blurRes}></div>
                                        <p className={classes.centeredRes}>{categories?.category}</p>
                                    </Box>
                                </Grid>)
                            }


                        </Grid>) : (<CircularProgress
                            sx={{ color: '#000000' }}
                            size={40}
                            thickness={4} />)
                    }
                </Box>
            </Container>

        </>
    );
};

export default CollectionsPageRetro;