import { Box, Grid, Typography, Container, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../../../InstanceAxios/InstanceAxios'
import FooterResponsiveTechne from './FooterResponsiveTechne';
import PhoneIcon from '../../../../images/logo/footer_phone.png';
import MailIcon from '../../../../images/logo/footer_mail.png';
import LocationIcon from '../../../../images/logo/footer_location.png';

const FooterTechne = () => {
    const [categories, setCategories] = useState([]);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [willQuickLinkAppear, setWillQuickLinkAppear] = useState(false);
    const [shopData, setShopData] = useState({});

    let shop = useSelector((state) => state?.product?.shop);
    const navigate = useNavigate();

    const hostName = window.location.hostname;
    const name = process.env.React_App_NAME;

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    shop = JSON.parse(localStorage.getItem('shop'));

    const getShopData = async () => {
        try {
            const response = await axios.get(`/merchants/getShopData/${shop?.shop_link}`);
            setShopData(response.data);
            if (response.data?.about_us?.length || response.data?.terms_and_conditions?.length) {
                setWillQuickLinkAppear(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const url = `/categories/allCategories?shop_id=${shop?.shop_id}`;
            const response = await axios.get(url);
            setCategories(response.data);
            getShopData();
        }
        fetchData();
    }, [shop?.shop_id]);

    const category = categories?.Product_Categories?.slice(0, 4);

    const handleCollectionClick = () => {
        setCollectionOpen('block')
    }

    const handleClick = id => {
        hostName == name ? navigate(`/${shop?.shop_link}/categoryDetails/${id}`) : navigate(`/categoryDetails/${id}`)
    }


    return (
        <>
            <Container
                sx={{
                    display: { xs: 'none', md: 'block' },
                    marginTop: '48px',
                }}
            >
                <Grid container >
                    <Grid
                        item
                        md={willQuickLinkAppear ? 3 : 4}
                        xs={12}
                        sx={{
                            textAlign: 'left',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#021F3F',
                                fontWeight: 500,
                                fontSize: '16px',
                                marginBottom: '24px',
                            }}
                        >
                            Popular Collections
                        </Typography>
                        <Box>
                            {
                                category?.map(category =>
                                    <Typography
                                        sx={{
                                            color: '#79747E',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            marginBottom: '12px',
                                            cursor: 'pointer',
                                        }}
                                        key={category?.category_id}
                                        onClick={() => handleClick(category?.category_id)}
                                    >
                                        {category?.category}
                                    </Typography>
                                )
                            }
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                        sx={{
                            textAlign: 'left',
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    color: '#021F3F',
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    marginBottom: '24px',
                                }}
                            >
                                Contact us
                            </Typography>
                            {
                                shop?.phone && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            marginBottom: '12px',
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={PhoneIcon}
                                            maxHeight="14px"
                                            maxWidth="14px"
                                            sx={{
                                                marginTop: '4px',
                                            }}
                                        ></Box>
                                        <Typography
                                            sx={{
                                                color: '#79747E',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                marginLeft: '8px',
                                            }}
                                        >
                                            {shop?.phone}
                                        </Typography>
                                    </Box>
                                )
                            }
                            {
                                shop?.email && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            marginBottom: '12px',
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={MailIcon}
                                            maxHeight="14px"
                                            maxWidth="14px"
                                            sx={{
                                                marginTop: '4px',
                                            }}
                                        >
                                        </Box>
                                        <Typography
                                            sx={{
                                                color: '#79747E',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                marginLeft: '8px',
                                            }}
                                        >
                                            {shop?.email}
                                        </Typography>
                                    </Box>
                                )
                            }
                            {
                                shop?.shop_address &&
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        marginBottom: '12px',
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={LocationIcon}
                                        maxHeight="14px"
                                        maxWidth="14px"
                                        sx={{
                                            marginTop: '4px',
                                        }}
                                    >
                                    </Box>
                                    <Typography
                                        sx={{
                                            color: '#79747E',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            marginLeft: '8px',
                                        }}
                                    >
                                        {shop?.shop_address}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Grid>
                    {
                        willQuickLinkAppear &&
                        <Grid
                            item
                            md={willQuickLinkAppear ? 3 : 0}
                            xs={12}
                            sx={{
                                textAlign: 'left',
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        color: '#021F3F',
                                        fontWeight: 500,
                                        fontSize: '16px',
                                        marginBottom: '24px',
                                    }}
                                >
                                    Quick Links
                                </Typography>
                                {
                                    shopData?.about_us?.length > 0 &&
                                    <Link
                                        to={hostName == name ? `/${shop?.shop_link}/aboutUsTechne` : '/aboutUsTechne'}
                                        style={{
                                            textDecoration: 'none',
                                            color: '#79747E',
                                            marginBottom: '12px',
                                            fontSize: '14px',
                                            fontFamily: 'quicksand'
                                        }}
                                    >
                                        About Us
                                    </Link>
                                }
                                <br />
                                {
                                    shopData?.terms_and_conditions?.length > 0 &&
                                    <Link
                                        to={
                                            hostName == name ?
                                                `/${shop?.shop_link}/termsAndConditionsTechne`
                                                :
                                                '/termsAndConditionsTechne'
                                        }
                                        style={{
                                            textDecoration: 'none',
                                            color: '#79747E',
                                            marginBottom: '12px',
                                            fontSize: '14px',
                                            fontFamily: 'quicksand'
                                        }}
                                    >
                                        Terms and condition
                                    </Link>
                                }
                            </Box>
                        </Grid>
                    }
                    {
                        // shop?.facebook_link || shop?.instagram_link &&
                        <Grid
                            item
                            md={willQuickLinkAppear ? 2 : 4}
                            xs={12}
                            sx={{
                                textAlign: 'left',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#021F3F',
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    marginBottom: '24px',
                                }}
                            >
                                Follow us
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {
                                    shop?.facebook_link && (
                                        <Link
                                            to={`//${shop?.facebook_link}`}
                                            target='_blank'
                                        >
                                            <Box
                                                sx={{
                                                    height: '30px',
                                                    width: '30px',
                                                    color: '#fff',
                                                    backgroundColor: '#D1D1D1',
                                                    border: '1px solid #D1D1D1',
                                                    borderRadius: '15px',
                                                    marginRight: '12px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <svg
                                                    width="11"
                                                    height="20"
                                                    viewBox="0 0 11 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M9.403 7.00004H7V5.00004C7 3.96804 7.084 3.31804 8.563 3.31804H9.431C9.983 3.31804 10.431 2.87004 10.431 2.31804V1.06404C10.431 0.541037 10.03 0.0940375 9.508 0.0590375C8.904 0.0180375 8.299 -0.000962531 7.693 3.74688e-05C4.98 3.74688e-05 3 1.65704 3 4.69904V7.00004H1C0.448 7.00004 0 7.44804 0 8.00004V10C0 10.552 0.448 11 1 11L3 10.999V19C3 19.552 3.448 20 4 20H6C6.552 20 7 19.552 7 19V10.997L9.174 10.996C9.682 10.996 10.109 10.615 10.167 10.11L10.396 8.11404C10.465 7.52104 10.001 7.00004 9.403 7.00004Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </Box>
                                        </Link>
                                    )}
                                {
                                    shop?.instagram_link && (
                                        <Link
                                            to={`//${shop?.instagram_link}`}
                                            target='_blank'
                                        >
                                            <Box
                                                sx={{
                                                    height: '30px',
                                                    width: '30px',
                                                    color: '#fff',
                                                    backgroundColor: '#D1D1D1',
                                                    border: '1px solid #D1D1D1',
                                                    borderRadius: '15px',
                                                    marginRight: '12px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <svg
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M5 0C2.239 0 0 2.239 0 5V13C0 15.761 2.239 18 5 18H13C15.761 18 18 15.761 18 13V5C18 2.239 15.761 0 13 0H5ZM15 2C15.552 2 16 2.448 16 3C16 3.552 15.552 4 15 4C14.448 4 14 3.552 14 3C14 2.448 14.448 2 15 2ZM9 4C11.761 4 14 6.239 14 9C14 11.761 11.761 14 9 14C6.239 14 4 11.761 4 9C4 6.239 6.239 4 9 4ZM9 6C8.20435 6 7.44129 6.31607 6.87868 6.87868C6.31607 7.44129 6 8.20435 6 9C6 9.79565 6.31607 10.5587 6.87868 11.1213C7.44129 11.6839 8.20435 12 9 12C9.79565 12 10.5587 11.6839 11.1213 11.1213C11.6839 10.5587 12 9.79565 12 9C12 8.20435 11.6839 7.44129 11.1213 6.87868C10.5587 6.31607 9.79565 6 9 6Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </Box>
                                        </Link>
                                    )
                                }
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Container>
            <FooterResponsiveTechne
                category={category}
                shop={shop}
                shopData={shopData}
                willQuickLinkAppear={willQuickLinkAppear}
            />
            <Box
                sx={{
                    margin: '48px 0',
                }}
            >
                <Link
                    to={{
                        pathname: `//www.bonikapp.com/`
                    }}
                    target='_blank'
                    style={{
                        textDecoration: 'none',
                        color: '#79747E',
                        fontSize: '14px',
                        textAlign: 'center',
                        fontFamily: 'Quicksand',
                    }}
                >
                    Powered by Bonik
                </Link>
            </Box>
        </>
    );
};

export default FooterTechne;