import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, CircularProgress, Container, Grid } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import viewMore from '../../../../images/logo/viewmore_r.svg'
import axios from '../../../../InstanceAxios/InstanceAxios'
import NavBarRetro from '../../SharedRetro/NavBarRetro/NavBarRetro';
import FooterRetro from '../../SharedRetro/FooterRetro/FooterRetro';
import CardResponsiveRetro from '../../SharedRetro/Card/CardResponsiveRetro/CardResponsiveRetro';
import CardRetro from '../../SharedRetro/Card/CardRetro/CardRetro';


const useStyles = makeStyles({
    img: {

        objectFit: 'cover',
        width: '100%',
        height: '338px',
    },
    imgRes: {

        objectFit: 'cover',
        width: '100%',
        height: '200px',
    },
    title: {
        textAlign: 'left',
        fontSize: '32px',
        lineHeight: '40px',
        fontWeight: 400,
        color: '#000000',
        marginTop: '80px',
        marginBottom: '48px'
    },
    centered: {
        position: 'absolute',
        marginTop: '150px',
        marginLeft: '500px',
        fontSize: '22px',
        transform: 'translate(-50%, -50%)',
        color: '#FFFFFF',
        fontFamily: 'Quicksand'
    },
    centeredRes: {
        position: 'absolute',
        marginTop: '-100px',
        marginLeft: '48%',
        transform: 'translate(-50%, -50%)',
        color: '#FFFFFF',
        fontFamily: 'Quicksand'
    },
    blur: {
        background: 'rgba(102, 102, 102, 0.7);',
        backdropFilter: '40%',
        width: '100%',
        height: '338px',
        marginTop: '-338px'
    },
    blurRes: {
        background: 'rgba(102, 102, 102, 0.7)',
        backdropFilter: 'blur(0px)',
        width: '100%',
        height: '200px',
        marginTop: '-200px'
    },
    sort: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '36px',
        color: '#000000',
        textAlign: 'left',
        marginBottom: '24px',
        fontFamily: 'Quicksand'

    },
    sortBox: {
        listStyle: 'none',
        textAlign: 'left',
    },
    li: {
        color: '#000000',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        marginBottom: '28px',
        fontFamily: 'Quicksand'
    },




});

const CollectionDetailsPageRetro = () => {

    const { cid, shop_link } = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [categoryDetails, setCategoryDetails] = useState([]);
    const [sorted, setSorted] = useState(categoryDetails?.CategoryWitProducts?.products)

    // console.log(cid);
    let shop = useSelector((state) => state?.product?.shop)
    // shop = JSON.parse(localStorage.getItem('shop'));

    const name = process.env.React_App_NAME;

    const hostName = window.location.hostname;

    const url = `/productCategories/getAllproducts/?category_id=${cid}&shop_id=${shop?.shop_id}&source=webStore`
    useEffect(() => {


        const fetchData = async () => {
            const response = await axios.get(url)
            setCategoryDetails(response.data)
        }
        fetchData();

    }, [cid, shop?.shop_id])
    // console.log(categoryDetails?.CategoryWitProducts);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleAsc = () => {
        categoryDetails?.CategoryWitProducts?.products?.sort((a, b) => a.product_name.localeCompare(b.product_name)

        )
        setSorted(...categoryDetails?.CategoryWitProducts?.products)
        // console.log(sorted);

    }
    const handleDesc = () => {
        categoryDetails?.CategoryWitProducts?.products?.sort((a, b) => b.product_name.localeCompare(a.product_name)

        )
        setSorted(...categoryDetails?.CategoryWitProducts?.products)
        // console.log(sorted);

    }
    const handlePriceHTL = () => {
        categoryDetails?.CategoryWitProducts?.products?.sort((a, b) => b.unit_selling_price - a.unit_selling_price)

        setSorted(...categoryDetails?.CategoryWitProducts?.products)
        // console.log(sorted);

    }
    const handlePriceLTH = () => {
        categoryDetails?.CategoryWitProducts?.products?.sort((a, b) => a.unit_selling_price - b.unit_selling_price)

        setSorted(...categoryDetails?.CategoryWitProducts?.products)
        // console.log(sorted);
    }



    //responsive Sort


    const [state, setState] = React.useState({
        bottom: false,

    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>

                <ListItem disablePadding>
                    <ListItemButton onClick={handleAsc}>

                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#000000',
                                fontFamily: 'Montserrat'
                            }}
                            primary='Ascending: A-Z' />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleDesc}>

                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#000000',
                                fontFamily: 'Montserrat'
                            }}
                            primary='Descending: A-Z' />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={handlePriceHTL}>

                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#000000',
                                fontFamily: 'Montserrat'
                            }}
                            primary='Price: High to Low' />

                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={handlePriceLTH}>

                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#000000',
                                fontFamily: 'Montserrat'
                            }}
                            primary='Price: Low to High' />
                    </ListItemButton>
                </ListItem>

            </List>
        </Box>
    );


    return (
        <>
            <NavBarRetro />
            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>{categoryDetails?.CategoryWitProducts?.category}</title>
                <meta name="title" content={categoryDetails?.CategoryWitProducts?.category} />
                <link rel="canonical" href="https://www.bonikapp.com/categoryDetails/:cid" />
                <link rel="apple-touch-icon" href={categoryDetails?.CategoryWitProducts?.img_url} />
                <meta property="og:image" content={categoryDetails?.CategoryWitProducts?.img_url} />

            </Helmet> */}

            {
                categoryDetails ? '' : (<CircularProgress
                    sx={{ color: '#000000', marginLeft: '50%' }}
                    size={40}
                    thickness={4} />)
            }
            <Container sx={{ display: { xs: 'none', md: 'block' }, marginTop: '112px' }}>
                <Grid container spacing={3} >
                    <Grid item md={2}>
                        <p className={classes.sort}>SortBy</p>
                        <Box className={classes.sortBox}>
                            <li onClick={handleAsc} className={classes.li}>Ascending: A-Z</li>
                            <li onClick={handleDesc} className={classes.li}>Descending: A-Z</li>
                            <li onClick={handlePriceHTL} className={classes.li}>Price: High to Low</li>
                            <li onClick={handlePriceLTH} className={classes.li}>Price: Low to High</li>

                        </Box>
                        <hr />

                    </Grid>
                    <Grid item md={10}>
                        <Grid container spacing={3}>
                            <img className={classes.img} src={categoryDetails?.CategoryWitProducts?.img_url} />
                            <div className={classes.blur}></div>
                            <p className={classes.centered}>{categoryDetails?.CategoryWitProducts?.category}</p>
                            {
                                categoryDetails?.CategoryWitProducts?.products?.map(product => <Grid key={product.product_id} item md={4}> <CardRetro product={product}></CardRetro></Grid>)
                            }

                        </Grid>

                    </Grid>
                </Grid>

            </Container>
            <Container sx={{ display: { xs: 'block', md: 'none' }, marginTop: '45px' }}>


                <img className={classes.imgRes} src={categoryDetails?.CategoryWitProducts?.img_url} />
                <div className={classes.blurRes}></div>
                {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
                <p className={classes.centeredRes}>{categoryDetails?.CategoryWitProducts?.category}</p>
                {/* </Box> */}


                {/* start */}
                {['bottom'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button sx={{ textTransform: 'none', color: '#95989A', left: '40%', fontFamily: 'Quicksand', height: '40px', fontSize: '14px', lineHeight: '20px' }} onClick={toggleDrawer(anchor, true)}>Sort by<img src={viewMore} /></Button>
                        <SwipeableDrawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            {list(anchor)}
                        </SwipeableDrawer>
                    </React.Fragment>
                ))}

                {/* end */}

                <Grid container spacing={2}>
                    {
                        categoryDetails?.CategoryWitProducts ? (categoryDetails?.CategoryWitProducts?.products?.map(product => <Grid item xs={6}>
                            <CardResponsiveRetro product={product}></CardResponsiveRetro>
                        </Grid>))
                            : (<CircularProgress
                                sx={{ color: '#000000', marginLeft: '50%' }}
                                size={40}
                                thickness={4} />)

                    }

                </Grid>

            </Container>
            <FooterRetro></FooterRetro>
        </>
    );
};

export default CollectionDetailsPageRetro;